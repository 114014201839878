import { defineStore } from 'pinia'
import { filter } from '@prismicio/client';

import { usePrismicClient } from '~/composables/usePrismicClient'
import type { GenericRateType } from '@/types'
import { asLink } from '@prismicio/helpers';

export const useNewRatesStore = defineStore('new-rates', () => {
  const { loadRateByIdOrSlug, getRateByIdOrSlug } = useRateStore()
  const { client } = usePrismicClient()
  const { setLoading } = useUIStore()

  const newRateList = ref<(GenericRateType)[]>([])

  function isNotUndefined<T>(value: T | undefined): value is T {
    return value !== undefined;
  }

  const parseNewRateList = async (doc: Record<string, any>): Promise<GenericRateType | undefined> => {
    try {
      const assetUrl = asLink(doc.asset_url) || ''
      const category = doc.new_rate.type?.split('_')[1]

      await loadRateByIdOrSlug(doc.new_rate.uid || doc.new_rate.id, category)
      const rate = await getRateByIdOrSlug(doc.new_rate.uid || doc.new_rate.id)

      return Promise.resolve({
        category,
        id: doc.new_rate.id,
        name: rate?.name || '',
        brandId: rate?.brandId || '',
        assetUrl,
        fallbackAssetUrl: rate?.assetUrl,
        esclusive: !!rate?.esclusive,
        slug: doc.new_rate.slug
      })
    } catch (error) {
      return Promise.reject('Error parsing new rate from Prismic' + error)
    }
  }

  const fetchNewRateList = async (): Promise<GenericRateType[]> => {
    try {
      setLoading(true);

      const response = await client.get({
        filters: [
          filter.at('document.type', `new_rates`),
        ],
        pageSize: 10
      });

      const results: Record<string, any>[] = response.results.map(({ data, id, uid }) => ({ ...data, id, slug: uid }));

      if (results.length === 0) {
        return [];
      }

      const promises = results.map(parseNewRateList);

      const parsedResponse = await Promise.all(promises);

      return parsedResponse.filter(isNotUndefined);
    } catch (error) {
      console.error('Error fetching new rate data from Prismic:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };


  const loadNewRateList = async (): Promise<boolean> => {
    newRateList.value = await fetchNewRateList() || []
    return Promise.resolve(true)
  }

  return {
    newRateList,

    fetchNewRateList,
    loadNewRateList,
  }
})
